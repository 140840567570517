<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage vendors</div>
      </portal>
      
      <ActionOutlinedButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :to="{ name: 'CreateVendor'}">
        Create vendor
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
  
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
    </div>
    
    <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options="options"
        :custom-sort="() => items"
        :expanded.sync="expanded"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        show-expand
        :loader-height="2"
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
  
        <template #item.name="{ item }">
          <span class="font-normal text-no-wrap">{{ item.name }}</span>
        </template>
  
<!--        <template #item.create_numbers="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['create_numbers']"-->
<!--            @input="changeOption(item, 'create_numbers')"-->
<!--            :disabled="!!item.private"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
<!--  -->
<!--        <template #item.see_record="{ item }">-->
<!--          <SwitchSm-->
<!--            :value="!!item['see_record']"-->
<!--            @input="changeOption(item, 'see_record')"-->
<!--            :disabled="!!item.private"-->
<!--            :loading="loadingStatus"-->
<!--            bgColor="switcherViolet"-->
<!--          />-->
<!--        </template>-->
  
        <template #item.status_id="{ item }">
          <SwitchSm
            :value="getStatus(item)"
            @input="changeStatus(item)"
            :disabled="!!item.private"
            :loading="loadingStatus"
            bgColor="switcherYellow"
          />
        </template>
  
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Invite" v-if="!item.private">
            <template #activator>
              <v-icon :class="item.private ? '' : 'mr-6'" @click="openInviteUser(item)">$userPlusIcon</v-icon>
            </template>
          </TooltipAction>
    
          <TooltipAction bottom message="Edit" v-if="!item.private">
            <template #activator>
              <v-icon class="mr-6" @click="openEditVendor(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
    
          <TooltipAction bottom message="Delete" v-if="!item.private">
            <template #activator>
              <v-icon @click="openDeleteVendorDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
  
        <template #item.data-table-expand="{ expand, isExpanded, item }">
          <v-icon @click="expand(!isExpanded)" v-if="!item.private">$cornerDown</v-icon>
        </template>
  
        <template #expanded-item="{ headers, item }">
          <td class="cardChildBg pa-0" :colspan="headers.length">
            <v-expand-transition>
              <v-data-table
                class="elevation-0 cardChildBg"
                :headers="headersChild"
                :items="item.invitations"
                item-key="slug"
                outlined
                hide-default-footer
                hide-default-header
                disable-pagination
                :mobile-breakpoint="0"
              >
                <template #item.first_name="{ item }">
                  <span class="childNameColor--text pl-26">{{ item['first_name'] }}</span>
                </template>
          
                <template #item.last_name="{ item }">
                  <span class="childNameColor--text">{{ item['last_name'] }}</span>
                </template>
          
                <template #item.email="{ item }">
                  <span class="childEmailColor--text">{{ item.email }}</span>
                </template>
          
                <template #item.accepted="{ item }">
                  <span v-if="item.accepted" class="acceptedColor--text">Accepted</span>
                  <span v-else class="pendingColor--text">Pending</span>
                </template>
          
                <template #item.actions="{ item }">
                  <div class="d-flex justify-end">
                    <TooltipAction bottom message="Resend" v-if="!item.accepted">
                      <template #activator>
                        <v-icon class="mr-6" @click="openResendInvite(item)">$resendIcon</v-icon>
                      </template>
                    </TooltipAction>
              
                    <TooltipAction bottom message="Delete">
                      <template #activator>
                        <v-icon @click="openDeleteUser(item)">$deleteIcon</v-icon>
                      </template>
                    </TooltipAction>
                  </div>
                </template>
              </v-data-table>
            </v-expand-transition>
          </td>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :loading="loadingAction"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      @actionSuccess="actionSuccess"
    />
  
    <DialogVendorsInvite
      v-model="inviteDialog"
      :vendorSlug="dialogVendorSlug"
      @actionSuccess="inviteSuccess"
    />
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogVendorsInvite from '@/components/dialog/DialogVendorsInvite'
import TablePagination from '@/components/pagination/TablePagination'

import { getVendors, updateVendor, deleteVendor } from '@/api/vendor-routes'
import { resendInvite, deleteInvitedUser } from '@/api/user-routes'
import { getOnPage, changeItemsAfterUpdate, parsePaginationData } from '@/helper/app-helper'

export default {
  name: 'ManageVendors',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, SwitchSm, TooltipAction, DialogConfirm, DialogVendorsInvite,TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    options: {},
    headers: [
      { text: '', value: 'data-table-expand', sortable: false },
      { text: 'Name', value: 'name', sortId: 'byName' },
      // { text: 'Create numbers', value: 'create_numbers', align: 'center', sortId: 'byCreateNumbers' },
      // { text: 'See record', value: 'see_record', align: 'center', sortId: 'bySeeRecord' },
      { text: 'Status', value: 'status_id', align: 'center', sortId: 'byStatus' },
      { text: 'Actions', value: 'actions', sortable: false, width: 140, align: 'right' },
    ],
    headersChild: [
      { text: 'First Name', value: 'first_name', class: 'text-no-wrap' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Email', value: 'email' },
      { text: 'Status', value: 'accepted' },
      { text: 'Actions', value: 'actions', align: 'right' }
    ],
    expanded: [],
    inviteDialog: false,
    dialogVendorSlug: null,
    confirmDialog: false,
    actionName: null,
    selectedItem: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadVendors() {
      this.loading = true

      const { success, payload, message } = await getVendors({
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        ...this.serverSorting,
      })

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadVendors()
      }
      this.$vuetify.goTo(0)
    },
    changeOption(vendor, option) {
      const formData = {
        name: vendor.name,
        'status_id': vendor['status_id'],
        'create_numbers': vendor['create_numbers'],
        'see_record': vendor['see_record'],
      }
      formData[option] = !!vendor[option] ? 0 : 1
      this.updateVendor({
        vendorId: vendor.slug,
        formData
      })
    },
    changeStatus(vendor) {
      const formData = {
        name: vendor.name,
        'status_id': vendor['status_id'] === 1 ? 2 : 1,
        'create_numbers': vendor['create_numbers'],
        'see_record': vendor['see_record'],
      }
      this.updateVendor({
        vendorId: vendor.slug,
        formData,
      })
    },
    async updateVendor({ vendorId, formData }) {
      this.loadingStatus = true
      
      const { success, payload, message } = await updateVendor({ vendorId, formData })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.items = changeItemsAfterUpdate(this.items, payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    openEditVendor(vendor) {
      this.$router.push({ name: 'DetailVendor', params: { slug: vendor.slug }})
    },
    openInviteUser(vendor) {
      this.dialogVendorSlug = vendor.slug
      this.inviteDialog = true
    },
    inviteSuccess(payout) {
      this.loadVendors()
    },
    actionSuccess() {
      this[this.actionName]()
    },
    openResendInvite(user) {
      this.actionName = 'resendInvite'
      this.selectedItem = user
      this.dialogTitle = 'Resend invite'
      this.dialogSubTitle = `${user.email}`
      this.dialogMessage = 'Are you sure you want to resend the invite to this user?'
      this.confirmDialog = true
    },
    async resendInvite() {
      this.loadingAction = true
      
      const { success, message } = await resendInvite(this.selectedItem.slug)
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openDeleteUser(user) {
      this.actionName = 'deleteInvitedUser'
      this.dialogTitle = 'Delete user'
      this.selectedItem = user
      this.dialogSubTitle = `${user.first_name} ${user.last_name}`
      this.dialogMessage = 'Are you sure you want to delete this user?'
      this.confirmDialog = true
    },
    async deleteInvitedUser() {
      this.loadingAction = true

      const { success, message } = await deleteInvitedUser(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.loadVendors()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openDeleteVendorDialog(vendor) {
      this.actionName = 'deleteVendor'
      this.selectedItem = vendor
      this.dialogTitle = 'Delete vendor'
      this.dialogSubTitle = `${vendor.name}`
      this.dialogMessage = 'Are you sure you want to delete this vendor?'
      this.confirmDialog = true
    },
    async deleteVendor() {
      this.loadingAction = true

      const { success, message } = await deleteVendor(this.selectedItem.slug)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadVendors()
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadVendors()
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadVendors()
      },
    },
  },
}
</script>
