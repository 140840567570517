<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    content-class="cardBg dialogConfirm"
    max-width="594px"
    overlay-color="overlayColor"
  >
    <v-card
      class="cardBg py-25 py-sm-32"
      :class="isMobile ? 'px-10' : 'px-32'"
      outlined
    >
      <v-form ref="form" v-model="validateForm">
        <v-row>
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-11 pb-10"
            cols="12" sm="5"
          >
            <span class="font-large-title text-uppercase grayLighten3--text">Invite User</span>
          </v-col>
          <v-col cols="12" sm="7" class="pa-0 pa-sm-3"></v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5"
          >
            <span class="font-normal grayLighten3--text">Email</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="inviteVendorEmail"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10 pt-0 pt-sm-3" cols="12" sm="7">
            <FormField
              v-model="formData.email"
              class="field-container"
              :rules="emailRules"
            >
              <template #message="{ key, message }">
                <portal to="inviteVendorEmail" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5"
          >
            <span class="font-normal grayLighten3--text">First Name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="inviteVendorFirstName"
            ></portal-target>
          </v-col>
          <v-col class="pb-10 pt-0 pt-sm-3" cols="12" sm="7">
            <FormField
              v-model="formData['first_name']"
              class="field-container"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="inviteVendorFirstName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
          
          <v-col
            class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="5"
          >
            <span class="font-normal grayLighten3--text">Last Name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="inviteVendorLastName"
            ></portal-target>
          </v-col>
          <v-col class="pb-10 pt-0 pt-sm-3" cols="12" sm="7">
            <FormField
              v-model="formData['last_name']"
              class="field-container"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="inviteVendorLastName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
        </v-row>
      </v-form>
      
      <v-card-actions class="justify-start justify-sm-end pr-0 pt-0 mt-2 mt-sm-8">
        <ActionButton
          :loading="loading"
          @click="submitForm"
          class="mr-8 mr-sm-10"
        >
          Invite
        </ActionButton>
        
        <CancelButton @click="closeDialog">Cancel</CancelButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'

import { inviteUserToVendor } from '@/api/user-routes'

export default {
  name: 'DialogVendorsInvite',
  inheritAttrs: false,
  components: { FormField, ActionButton, CancelButton },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    vendorSlug: {
      type: String | Number,
      required: true
    },
  },
  data: () => ({
    validateForm: false,
    loading: false,
    formData: {
      email: null,
      first_name: null,
      last_name: null
    },
    rules: [],
    emailRules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 450
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loading = true
        
        const { success, payload, message } = await inviteUserToVendor({
          unit: this.vendorSlug,
          ...this.formData,
        })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.$emit('actionSuccess', payload)
          this.closeDialog()
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
        
        this.loading = false
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.rules = [
        v => !!v || 'This field is required',
        v => !!v && v.length >= 2 || 'Name cannot be shorter than two characters'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (!value) {
          this.rules = []
          this.emailRules = []
          this.formData = {
            email: null,
            first_name: null,
            last_name: null
          }
        }
      },
    },
  },
}
</script>
