import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const inviteUserToVendor = formData => axios.post(`${getAccountId()}/invite/publisher`, formData)
const inviteUserToBuyer = formData => axios.post(`${getAccountId()}/invite/buyer`, formData)
const resendInvite = userId => axios.post(`${getAccountId()}/invite/${userId}`)
const deleteInvitedUser = userId => axios.delete(`${getAccountId()}/invite/${userId}`)

export {
  inviteUserToVendor, inviteUserToBuyer, resendInvite, deleteInvitedUser
}